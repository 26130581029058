export function BidBadge({ children, className = "", variant = "default", ...props }) {
    let badgeClasses = "";
    if (props.cards == 2) {
        badgeClasses = "flex gap-x-3 w-full items-center px-3 py-[3.5px] text-neutral-50 tracking-[0.08px] text-[12px]";
    } else {
        badgeClasses = "flex gap-x-3 rounded-[8px] items-center px-3 py-[3.5px] text-neutral-50 tracking-[0.08px] text-[14px]";
    }

    switch (variant) {
        case "warning":
            badgeClasses += " bg-yellow-500"
            break;
        case "danger":
            badgeClasses += " bg-red-500"
            break;
        case "lastcall":
            badgeClasses += " bg-orange-500"
            break;
        default:
            badgeClasses += " bg-black"
            break;
    }

    return (
        <div className={`${badgeClasses} ${className}`} {...props}>
            {children}
        </div>
    )
}